<template>
  <div>
    <div v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
    <div style="margin-top: 16px;width: 100%;height: 50vh;overflow-y: auto;overflow-x: hidden">
      <div v-if="orders.length===0"
           style="height: 100%;width: 100%;display: flex;align-items: center;justify-content: center">
        <span style="color: #777;font-size: 18px;font-weight: 400;">No orders available</span>
      </div>
      <v-expansion-panels v-else flat style="width: 100%">
        <v-expansion-panel
            v-for="(order, index) in orders"
            :key="index"
            style="margin-top: 12px"
        >
          <div class="display color-dark" @click="viewOrderDetails(order.orderId)">
            <p class="fs-14 fw-500">Invoice #{{ order.invoiceNumber }}</p>
            <p class="fs-12 fw-300 ml-2">{{ order.createdDate }}</p>
          </div>
          <v-expansion-panel-content class="color-dark" @click.native="viewOrderDetails(order.orderId)">
            <div class="display color-dark fs-14 fw-400">
              <p>{{ order.productName ? order.productName : "N/A" }}</p>
              <p class="ml-2">৳{{ order.amount }}</p>
            </div>
            <div class="display color-dark fs-12 fw-400">
              <p>Wallet : {{ order.customerPhoneNumber }}</p>
              <p class="ml-2">Txn ID : {{ order.trxId }}</p>
            </div>
            <p class="color-dark fs-12 fw-400">Link ID : {{ order.paymentLinkId }}</p>
          </v-expansion-panel-content>
          <v-expansion-panel-header style="margin:8px 0 12px 0">
            <div style="display: inline-flex;align-items: center; gap: 2px">
            <span
                :style="{color: order.orderStatus==='Incomplete'? '#FFB829' : '#01846C'}"
                class="mdi mdi-circle"
            >
            </span>
              <p class="color-light fw-400 fs-12">{{ order.orderStatus }}</p>
            </div>
          </v-expansion-panel-header>
          <v-divider style="color: #EEE; width: 100%;"></v-divider>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>

export default {
  name: "OrderList",
  props: {
    orders: Array,
    loading: Boolean,
    refreshClicked: Boolean
  },

  data() {
    return {
      isLoading: false,
    }
  },

  watch: {
    loading: function (val) {
      this.isLoading = val === true;
    },

  },
  methods: {
    viewOrderDetails(orderId) {
      this.$router.push({
        name: "order-management-details",
        params: {orderId}
      });
    },
  }
}
</script>

<style scoped>
p {
  margin: 0 !important;
}
.ml-2 {
  margin-left: 2px;
}
>>> .mdi {
  font-size: 10px;
}

.display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-dark {
  color: #464646;
}

.color-light {
  color: #777;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

>>> .v-expansion-panel-content__wrap {
  padding: 0;
}

>>> .v-expansion-panel-header {
  padding: 0;
}

>>> .v-icon.v-icon {
  font-size: 14px;
}

>>> .v-expansion-panel-header__icon {
  border-radius: 14px;
  background: #F2F2F2;
}

>>> .v-expansion-panel-header {
  min-height: unset;
}
</style>