<template>
  <div style="display: flex; align-items: center; justify-content: space-between; gap: 12px">
    <v-select
        :items="filterByDateItems"
        label="Date"
        placeholder="Date"
        v-model="selectedDateToFilter"
        @change="setDateRange"
        class="filter filter-date"
        hide-details
        append-icon="mdi-chevron-down"
        single-line
        clear-icon="mdi-close"
        clearable
        dense
    >
    </v-select>
    <div class="date-range" v-if="selectedDateToFilter==='Custom'">
      <date-picker
          v-model="dateRange"
          @change="setCustomDateRange"
          type="date"
          range
          placeholder="Select date range"
          class="date-picker simple-stretch-on-mobile"
          :disabled-date="filterDisableDates"
      >
      </date-picker>
    </div>
    <v-select
        :items="filterByStatusItems"
        label="Status"
        placeholder="Status"
        v-model="selectedStatus"
        @change="setStatus"
        class="filter filter-status"
        hide-details
        append-icon="mdi-chevron-down"
        single-line
        clear-icon="mdi-close"
        clearable
        dense
    >
    </v-select>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "FilterOrders",
  props: ["refreshClicked"],

  data(){
    return {
      filterByDateItems : ["7 Days", "30 Days", "Custom"],
      selectedDateToFilter : null,
      dateRange : [],

      filterByStatusItems : ['Completed', 'Incomplete'],
      selectedStatus : null,

      filters: {
        fromDate: null,
        toDate: null,
        status: null
      },
    }
  },

  methods: {
    filterDisableDates(date) {
      const today = new Date();
      const priorDate = new Date(new Date().setDate((new Date()).getDate() - 90));
      return date.getTime() < priorDate.getTime() || date.getTime() > today.getTime();
    },

    setDateRange(){
      switch (this.selectedDateToFilter){
        case "7 Days":
          this.filters.fromDate = moment(new Date(new Date().setDate((new Date()).getDate() - 6))).format('YYYY-MM-DD 00:00:00');
          this.filters.toDate = moment(new Date()).format('YYYY-MM-DD 00:00:00');
          this.handleFilter();
          break;
        case "30 Days":
          this.filters.fromDate = moment(new Date(new Date().setDate((new Date()).getDate() - 29))).format('YYYY-MM-DD 00:00:00');
          this.filters.toDate = moment(new Date()).format('YYYY-MM-DD 00:00:00');
          this.handleFilter();
          break;
        case "Custom":
          if (this.dateRange[0] && this.dateRange[1]){
            this.filters.fromDate = moment(this.dateRange[0]).format('YYYY-MM-DD 00:00:00');
            this.filters.toDate = moment(this.dateRange[1]).format('YYYY-MM-DD 00:00:00');
            this.handleFilter();
          }
          break;
        default:
          this.dateRange = [];
          this.filters.fromDate = null;
          this.filters.toDate = null;
          this.handleFilter();
      }
    },

    setStatus(){
      this.filters.status = this.selectedStatus ? this.selectedStatus : null;
      this.handleFilter();
    },

    setCustomDateRange(){
      if (this.dateRange[0] && this.dateRange[1]){
        this.filters.fromDate = moment(this.dateRange[0]).format('YYYY-MM-DD 00:00:00');
        this.filters.toDate = moment(this.dateRange[1]).format('YYYY-MM-DD 00:00:00');
        this.handleFilter();
      } else {
        this.filters.fromDate = null;
        this.filters.toDate = null;
        this.handleFilter();
      }
    },

    handleFilter(){
      this.$emit('handleFilter', this.filters);
    },
  },

  watch: {
    refreshClicked: function (){
      this.selectedDateToFilter = null;
      this.selectedStatus = null;
    }
  }
}
</script>

<style scoped>
.filter {
  height: 40px;
  outline-style: none !important;
  border: 1px solid #DDD;
  border-radius: 4px;
}
.filter-date {
  width: 145px;
}
.filter-status {
  width: 160px;
}
.date-picker {
  margin: 0;
}
.mx-datepicker-range {
  width: 232px;
}
>>> .mx-input-wrapper > input {
  border: 1px solid #DDD;
  border-radius: 4px;
  height: 40px;
}
>>> .v-input {
  display: flex;
  align-items: center;
}
>>> .v-input__slot::before {
  border-style: none !important;
}
>>> .v-text-field {
  margin-top: 0 !important;
}
>>> .v-select__slot {
  padding: 5px;
}
>>> .v-icon {
  font-size: 20px;
}
>>> .v-select.v-input--dense .v-select__selection--comma {
  margin-right: 0;
}
>>> .v-text-field input {
  width: auto;
}
>>> .mx-icon-calendar {
  color: #e2136e;
}
>>> .mx-icon-clear {
  color: #e2136e;
}
</style>