<template>
  <div class="orders-container">
    <section class="orders-header">
      <div>
        <div style="display: flex; align-items: center;gap:10px">
          <h2 style="font-size: 24px">Orders</h2>
          <refresher @refresh-requested="refreshRequested"></refresher>
        </div>
        <p>{{ refreshTimeMessage }}</p>
      </div>

      <div v-if="showMarkAsCompleted" @click="openMarkDialog" style="display: flex; align-items: center;gap: 3px">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <circle cx="12" cy="12" r="9" fill="#E2136E"/>
          <path
              d="M12.0001 2C6.48603 2 2 6.48597 2 12C2 17.514 6.48603 22 12.0001 22C17.5142 22 22.0001 17.514 22.0001 12C22.0001 6.48597 17.5142 2 12.0001 2ZM12.0001 20.3607C7.38997 20.3607 3.63934 16.6102 3.63934 12C3.63934 7.3899 7.38997 3.63934 12.0001 3.63934C16.6102 3.63934 20.3608 7.3899 20.3608 12C20.3608 16.6102 16.6102 20.3607 12.0001 20.3607Z"
              fill="#E2136E"/>
          <path
              d="M16.3262 8.15996L10.3888 14.0975L7.67416 11.3828C7.35403 11.0628 6.83508 11.0628 6.51495 11.3828C6.19489 11.703 6.19489 12.2219 6.51495 12.5421L9.80918 15.8363C9.96925 15.9963 10.179 16.0764 10.3888 16.0764C10.5986 16.0764 10.8083 15.9963 10.9684 15.8363L17.4854 9.31924C17.8055 8.99911 17.8055 8.48016 17.4854 8.16003C17.1653 7.8399 16.6463 7.8399 16.3262 8.15996Z"
              fill="white"/>
        </svg>
        <span style="color: #e2136e; font-weight: 500;font-size: 18px;cursor: pointer">
          Mark Order Complete
        </span>
      </div>

      <v-dialog persistent v-model="markDialog" width="535">
        <v-card style="padding: 30px">
          <div style="display: flex; justify-content: space-between;align-items: center">
            <h2 class="color-dark">Mark Order Complete</h2>
            <v-icon @click="closeMarkDialog" color="primary">mdi-close</v-icon>
          </div>
          <p v-if="selectedOrders.length===1" class="color-dark" style="font-weight: 400; font-size: 18px;">Are you sure
            want to Mark this order complete?</p>
          <p v-else class="color-dark" style="font-weight: 400; font-size: 18px;">Are you sure want to Mark these
            {{ this.selectedOrders.length }} orders complete?</p>
          <div style="display: flex;align-items: center;gap:16px;">
            <v-btn @click="closeMarkDialog" color="primary" outlined>Cancel</v-btn>
            <v-btn @click="markOrderAsComplete" color="primary">Mark Complete</v-btn>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="markSuccessDialog" width="535">
        <v-card style="padding: 30px">
          <div style="display: flex; justify-content: space-between;align-items: start">
            <div>
              <img :src="successIcon" alt="successMark">
              <h2 class="color-dark">Mark Order Complete</h2>
            </div>
            <v-icon @click="closeSuccessDialog" color="primary">mdi-close</v-icon>
          </div>
          <p v-if="totalSelectedOrders===1">This order has been marked as completed</p>
          <p v-else>{{this.totalSelectedOrders}} orders have been marked as completed</p>
          <v-btn @click="closeSuccessDialog" color="primary" outlined>Close</v-btn>
        </v-card>
      </v-dialog>
    </section>

    <section class="display row" style="gap: 8px">
      <div class="filter-container display" style="gap: 20px; margin-bottom: 8px">
        <h4>Filter by</h4>
        <filter-orders @handleFilter="filterOrders" :refreshClicked="refreshClicked"></filter-orders>
      </div>
      <div class="display" style="margin-bottom: 8px;">
        <search @handleSearch="searchOrders" :refreshClicked="refreshClicked"></search>
        <v-btn @click="downloadReportClicked" color="primary" class="report-download-button">
          Download Report
        </v-btn>
      </div>

    </section>

    <section class="table">
      <order-list
          :orders="orders"
          :isLoading="isLoading"
          :payloadChanged="payloadChanged"
          @selectionChanged="handleSelectionChanged"
          ref="orderList"
      >
      </order-list>
      <pagination
          :isLoading="isLoading"
          :showPrevBtn="showPrevBtn"
          :showNextBtn="showNextBtn"
          :showCount="showCount"
          dataName="orders"
          @getPrevious="getPrevious"
          @getNext="getNext"
      ></pagination>
    </section>
  </div>
</template>

<script>
import Refresher from "@/components/orderManagement/components/Refresher.vue";
import FilterOrders from "@/components/orderManagement/components/FilterOrdersWeb.vue";
import Search from "@/components/orderManagement/components/Search.vue";
import OrderList from "@/components/orderManagement/components/OrderListWeb.vue";
import Pagination from "@/components/orderManagement/common/pagination.vue";
import successIcon from "@/assets/image/success-icon.svg";

import moment from "moment/moment";
import {generateCsv, initiateFileDownload} from "@/util/Helper";

const CLOCK_UPDATE_INTERVAL = process.env.VUE_APP_CLOCK_UPDATE_INTERVAL;
const AUTO_REFRESH_MIN_THRESHOLD = process.env.VUE_APP_AUTO_REFRESH_MIN_THRESHOLD;

export default {
  name: "OrderManagementWeb",
  components: {
    Pagination,
    OrderList,
    Search,
    FilterOrders,
    Refresher
  },

  created() {
    this.getOrderList();
    this.updateClock();
  },

  data() {
    return {
      isLoading: false,

      clockTime: null,
      lastRefreshed: null,
      wasLastRefreshSuccessful: false,
      refreshClicked: false,

      tokens: [" "],
      orders: [],
      payload: {
        fromDate: moment(new Date(new Date().setDate((new Date()).getDate() - 90))).format('YYYY-MM-DD 00:00:00'),
        toDate: null,
        orderStatus: null,
        searchString: null,
      },
      payloadChanged: false,

      selectedOrders: [],
      markAsComplete: false,
      markDialog: false,
      markSuccessDialog: false,
      successIcon: successIcon,

      totalSelectedOrders: null,
      itemsPerPage: 25,
    }
  },

  computed: {
    showMarkAsCompleted() {
      return this.markAsComplete;
    },

    refreshTimeMessage() {
      if (this.isLoading) {
        return "Updating...";
      }

      if (this.lastRefreshed === null) {
        return "Refresh failed";
      }

      let diff = Math.ceil((this.clockTime - this.lastRefreshed) / 1000 / 60);

      if (this.wasLastRefreshSuccessful) {
        if (diff < 2) return "Updated just now.";
        return `Last updated ${diff} minutes ago.`;
      }

      return `Refresh failed. Last updated ${diff} minutes ago.`;
    },

    showPrevBtn() {
      let leng = this.tokens.length;
      if (leng === 2) {
        return false;
      }
      return true;
    },

    showNextBtn() {
      let leng = this.tokens.length;
      if (this.tokens[leng - 1] === null) {
        return false;
      }
      return true;
    },

    showCount() {
      if (this.orders.length === 0) return [0, 0];

      let leng = this.tokens.length;
      return [1 + this.itemsPerPage * (leng - 2), this.orders.length + this.itemsPerPage * (leng - 2)]
    }
  },

  methods: {
    updateClock() {
      this.clockTime = Date.now();

      if (this.lastRefreshed) {
        let diff = this.clockTime - this.lastRefreshed;
        if (diff > 120 * AUTO_REFRESH_MIN_THRESHOLD) { // auto refresh after 1 hour
          this.refreshRequested();
        }
      }

      if (this.$store.getters.isAuthenticated) {
        setTimeout(() => {
          this.updateClock();
        }, 30 * CLOCK_UPDATE_INTERVAL);
      }
    },

    clearTokens() {
      this.tokens = [" "];
      this.payload.nextToken = this.tokens[0];
    },

    async getOrderList() {
      this.isLoading = true;
      this.$feedback.showLoading();
      try {
        let data = await this.$cppClient.post('/order/list', this.payload);
        this.orders = data.data.orders;
        this.tokens.push(data.data.nextToken);

        this.lastRefreshed = Date.now();
        this.wasLastRefreshSuccessful = true;
      } catch (err) {
        // this.orders = [];
        this.wasLastRefreshSuccessful = false;
        await this.$feedback.showFailed(err.response);
      } finally {
        this.isLoading = false;
        this.refreshClicked = false;
        this.payloadChanged = false;
        this.$feedback.hideLoading();
      }
    },

    async getNext() {
      this.payloadChanged = true; // select from current page only
      this.payload.nextToken = this.tokens[this.tokens.length - 1];
      await this.getOrderList();
    },

    async getPrevious() {
      this.tokens.pop(); // pop next page token
      this.tokens.pop(); // pop current page token
      this.payload.nextToken = this.tokens[this.tokens.length - 1];
      this.payloadChanged = true; // select from current page only

      await this.getOrderList();
    },

    async refreshRequested() {
      this.clearTokens();
      this.refreshClicked = true;

      this.payload.fromDate = null;
      this.payload.toDate = null;
      this.payload.orderStatus = null;
      this.payload.searchString = null;

      this.payloadChanged = true;

      await this.getOrderList();
    },

    async filterOrders(filters) {
      this.clearTokens();
      this.payload.fromDate = filters.fromDate;
      this.payload.toDate = filters.toDate;
      this.payload.orderStatus = filters.status;
      this.payloadChanged = true;

      await this.getOrderList();
    },

    async searchOrders(searchStr) {
      this.clearTokens();
      this.payload.searchString = searchStr ? searchStr.trim() : null;
      this.payloadChanged = true;

      await this.getOrderList();
    },

    async markOrderAsComplete() {
      this.isLoading = true;
      this.$feedback.showLoading();
      this.totalSelectedOrders = this.selectedOrders.length;
      try {
        let payload = {orderUpdateRequests: this.selectedOrders}
        let result = await this.$cppClient.post('/order-status/update', payload);

        this.markSuccessDialog = true;
        this.markDialog = false;

        console.debug({result});
      } catch (err) {
        await this.$feedback.showFailed(err.response);
      } finally {
        this.isLoading = false;
        // refresh current page
        this.tokens.pop(); //remove next page token
        this.payload.nextToken = this.tokens[this.tokens.length - 1]; // call api with current page
        this.payloadChanged = true;
        this.$feedback.hideLoading();
        await this.getOrderList();
      }
    },

    handleSelectionChanged(selectedOrders, shouldShowMarkAsComplete) {
      this.markAsComplete = shouldShowMarkAsComplete;
      this.selectedOrders = selectedOrders;
    },

    openMarkDialog() {
      this.markDialog = true;
    },

    closeMarkDialog() {
      this.markDialog = false;
      this.markSuccessDialog = false;
    },

    closeSuccessDialog() {
      this.markSuccessDialog = false;
    },

    async downloadReportClicked() {
      const sourceHeaders = [
        {text: "Invoice ID", value: "invoiceNumber"},
        {text: "Date", value: "createdDate"},
        {text: "Product Name", value: "productName"},
        {text: "Link ID", value: "paymentLinkId"},
        {text: "Cust Wallet", value: "customerPhoneNumber"},
        {text: "Amount", value: "amount"},
        {text: "Txn ID", value: "trxId"},
        {text: "Status", value: "orderStatus"},
        {text: "", value: "details"}
      ];

      let headers = sourceHeaders.filter(header => header.text && String(header.text).length > 0)
      console.debug({headers});

      let csvHeaders = headers.map(header => header.text);
      let csvRows = this.orders.map(order => {
        return headers.map(header => order[header.value]);
      });

      let csvContent = generateCsv(csvHeaders, csvRows);
      console.debug(csvContent)
      let csvFileName = `Order Report Page - ${(new Date().toJSON().slice(0, 10))}.csv`;

      initiateFileDownload(csvContent, csvFileName);
    }
  },
}
</script>

<style scoped>
.display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orders-container {
  margin: 96px 50px 0;
  background: #FFF;
  padding: 16px 32px;
}

.orders-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row {
  margin: 0;
}

.color-dark {
  color: #464646;
}

.color-light {
  color: #777;
}

.display >>> .v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
  margin-left: 12px;
}
</style>