<template>
  <div class="refresh-container" @click="refreshClicked">
    <a class="refresh-link">
      <img
          :src="refreshIcon"
          alt=""
          class="refresh-icon"
      />
      <span class="refresh-text">Refresh</span>
    </a>
  </div>
</template>
<script>
import RefreshIcon from "@/assets/image/refresh-icon.svg";

export default {
  name: "Refresher",

  data() {
    return {
      refreshIcon: RefreshIcon,
    }
  },

  methods: {
    refreshClicked() {
      this.$emit('refresh-requested');
    },
  }
}
</script>

<style scoped lang="scss">
.refresh-container {
  display: flex;
}

.refresh-link {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background-color: #ffedf5;
  padding: 2px 4px;
  border-radius: 20px;
  height: 20px;
  @media screen and (min-width: 1001px) {
    border-radius: 20px;
    height: 24px;
  }
}

.refresh-link span {
  margin-left: 3px;
}

.refresh-icon {
  width: 12px;
  height: 12px;
  @media screen and (min-width: 1001px) {
    width: 16px;
    height: 16px;
  }
}

.refresh-text {
  font-size: 12px;
  @media screen and (min-width: 1001px) {
    font-size: 14px;
  }
}
</style>