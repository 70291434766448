<template>
  <div class="orders-container">
    <order-management-mobile v-if="isMobile"></order-management-mobile>

    <order-management-web v-else></order-management-web>
  </div>
</template>

<script>
import OrderManagementMobile from "@/components/orderManagement/OrderManagementMobile.vue";
import OrderManagementWeb from "@/components/orderManagement/OrderManagementWeb.vue";

export default {
  name: "OrderManagement",
  components: {OrderManagementWeb, OrderManagementMobile},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    }
  }
}
</script>