<template>
  <div class="pagination">
    <div class="btn-container">
      <v-btn @click="emitPrevious"
             :disabled="isLoading || !showPrevBtn"
             outlined
             color="primary"
      >
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Previous
      </v-btn>
      <v-btn @click="emitNext"
             :disabled="isLoading || !showNextBtn"
             outlined
             color="primary"
      >
        Next
        <v-icon right>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
    <div v-if="!isLoading" class="msg">Showing {{ dataName }} from {{ showCount[0] }} to {{ showCount[1] }}</div>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    showPrevBtn: Boolean,
    showNextBtn: Boolean,
    isLoading: Boolean,
    dataName: String,
    showCount: Array
  },

  methods: {
    emitPrevious() {
      this.$emit('getPrevious');
    },
    emitNext() {
      this.$emit('getNext');
    }
  }
}
</script>

<style scoped lang="scss">
.pagination {
  @media screen and (min-width: 1001px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 1001px) {
    width: unset;
    justify-content: unset;
    gap: 20px;
  }
}

.msg {
  color: #464646;
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 8px;
  @media screen and (min-width: 1001px) {
    width: fit-content;
    display: block;
    justify-content: unset;
    margin-top: unset;
  }
}
</style>