<template>
  <div class="text-center">
    <v-dialog max-width="425px" v-model="cameraSelectorModal">
      <v-card>
        <div class="card-title">Select Camera</div>

        <div v-for="(camera) of cameraList" :key="camera.id" style="display: flex; margin: 12px;">
          <div>{{ camera.label }}</div>
          <v-btn color="primary" @click="selectCamera(camera)" style="margin: 8px;">
            Select
          </v-btn>
        </div>
        <div class="controls-container">
          <v-btn class="control-button" color="primary" @click="cancelCameraSelection">
            Exit
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog max-width="425px" v-model="modal">
      <v-card>
        <div class="card-title">Scan Invoice QR Code</div>
        <div id="video-container">
          <video id="qr-video" ref="vid1"></video>
        </div>

        <div class="controls-container">
          <v-btn class="control-button" color="primary" @click="cancelClicked">
            Exit
          </v-btn>
          <div style="flex: 1" class="empty"></div>
          <v-btn class="control-button" color="primary" @click="switchCameraClicked"
                 :disabled="false && cameraList.length < 2">
            Switch Camera
          </v-btn>
          <v-btn class="control-button" color="primary" @click="toggleFlashClicked" :disabled="!isFlashAvailable">
            Toggle Flash
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
function sleep(durationMillis) {
  return new Promise(accept => setTimeout(accept, durationMillis));
}

import QrScanner from "qr-scanner/qr-scanner.min"

export default {
  name: "QrScannerDialog",
  props: ['result'],
  created() {

  },
  data() {
    return {
      modal: false,
      isValid: true,
      shouldDisable: true,
      scanner: null,
      cameraList: [],
      isFlashAvailable: false,
      cameraSelectorModal: false,
      selectedCameraId: null
    };
  },
  methods: {
    async updateFlashAvailability() {
      try {
        console.debug("Updating Flash Availability");
        this.isFlashAvailable = await this.scanner.hasFlash();
        console.debug("Flash Availability Updated");
      } catch (ex) {
        console.error(ex);
      }
    },

    async toggleFlashClicked() {
      console.debug("Toggling flash");
      try {
        await this.scanner.toggleFlash();
        console.debug("Toggled flash");
      } catch (ex) {
        console.error(ex);
      }
    },

    async navigateToOrder(bbOrderCode) {
      console.log(bbOrderCode);
      await this.closeModal();
      await this.$router.push({path: `order-management/details/${bbOrderCode}`});
    },

    async successHandler(result) {
      if (result && result.data && String(result.data).length > 0) {
        let scannedText = String(result.data).trim();
        let parts = scannedText.split("|");
        console.debug(parts);
        if (
            parts.length != 3 ||
            parts[0] !== "BBORD" ||
            parts[2] !== "END" ||
            parts[1].length < 4
        ) {
          await this.decodeErrorHandler("Not a valid BB Order Code");
          return;
        }
        let bbOrderCode = parts[1];

        console.debug("BB Order Code: " + bbOrderCode);
        await this.scanner.stop();
        await this.navigateToOrder(bbOrderCode);
      }
    },

    async decodeErrorHandler(error) {
      console.debug(error);
    },

    async initiateScanning() {
      console.debug("Querying Camera Availability");
      let hasAtLeastOneCamera = await QrScanner.hasCamera();
      console.debug({hasAtLeastOneCamera});

      if (!hasAtLeastOneCamera) {
        alert("No camera detected. Ending scanning.");
        window.location.href = "index.html";
        return;
      }

      // set up the scanner
      console.debug("Setting up the Scanner");
      const successHandler = (...args) => this.successHandler(...args);
      const decodeErrorHandler = (...args) => this.decodeErrorHandler(...args);
      this.scanner = new QrScanner(this.$refs.vid1, successHandler, {
        onDecodeError: decodeErrorHandler,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      });

      // start scanner
      console.debug("Starting the scanner");
      await this.scanner.start();
      console.debug("Scanner started");

      // populate flash related information
      await this.updateFlashAvailability();

      // list all cameras for looping / toggling
      console.debug("Listing all cameras");
      this.cameraList = await QrScanner.listCameras(true);
      console.debug({cameraList: this.cameraList});
      console.debug("All cameras Listed");
    },

    async scan() {
      this.modal = true;
      await this.initiateScanning();
    },

    async closeModal() {
      this.scanner.stop();
      await sleep(100);
      this.modal = false;
    },

    async cancelClicked() {
      await this.closeModal();
    },

    async switchCameraClicked() {
      this.modal = false;
      this.cameraSelectorModal = true;
    },

    async cancelCameraSelection() {
      this.modal = true;
      this.cameraSelectorModal = false;
    },

    async selectCamera(camera) {
      this.modal = true;
      this.cameraSelectorModal = false;
      if (camera) {
        await this.scanner.setCamera(camera.id);
        await this.updateFlashAvailability();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#qr-video {
  width: 100%;
  max-height: 60vh;
}

#video-container {
  line-height: 0;
  padding: 12px;
}

.card-title {
  padding: 12px 12px 0;
  font-size: 20px;
  text-align: center;
}

.controls-container {
  padding: 12px;
  display: flex;
  text-align: center;
}

.control-button {
  margin-right: 4px;
}
@media screen and (max-width: 425px) {
  .empty {
    display: none;
  }

  .v-btn {
    font-size: 12px;
    min-width: unset;
  }
}

@media screen and (max-width: 375px) {
  .v-btn {
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 350px) {
  .v-btn {
    padding: 0 5px !important;
  }
}
</style>
