export function isRemoteLogo(arg) {
  if (!arg) throw Error('Method argument cannot be null');
  if (arg.startsWith('https://') || arg.startsWith('http://')) return true;
  else return false;
}

export function showErrorMessage(arg) {
  if (arg) {
    this.$feedback.showFailed(arg);
  } else this.$feedback.showFailed();
}

export function generateCsv(csvHeaders, csvRows) {
  const escapeAndJoinRow = (row) => {
    return row.map(cell => {
      if (/\s/.test(cell)) {
        return `"${cell}"`;
      }
      return cell;
    }).join(',')
  }

  let csvLines = [escapeAndJoinRow(csvHeaders), ...csvRows.map(row => escapeAndJoinRow(row))];
  let csvContent = csvLines.join('\n');
  return csvContent;
}

export function initiateFileDownload(content, fileNameForDownloading) {
  let blob = new Blob([content]);

  let url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileNameForDownloading;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 100);
}