<template>
  <div class="orders-container">
    <section class="orders-header">
      <div style="width: 100%; display: flex; gap: 10px; align-items: flex-start">
        <img :src="backIcon" @click="goBack" height="18px" width="9px" alt="back-icon" style="margin-top: 5px">
        <div style="width: 100%; display: flex; justify-content: space-between;">
          <div>
            <h2 style="font-size: 18px">Orders</h2>
            <p style="font-size: 12px; color: #777">{{ refreshTimeMessage }}</p>
          </div>
          <div style="cursor: pointer;display: flex;flex-direction: column;align-items: end">
            <div style="display: flex;gap: 2px;align-items: center; padding: 4px" @click="scanQrCode">
              <v-icon class="mdi-qrcode-scan" color="primary">mdi-qrcode-scan</v-icon>
              <span style="font-size: 12px">Scan</span>
            </div>
            <refresher class="mt-1" @refresh-requested="refreshRequested"></refresher>
          </div>
        </div>
      </div>
    </section>

    <qr-scanner-dialog ref="qrScannerDialog1"></qr-scanner-dialog>

    <section>
      <div class="display">
        <filter-orders-mobile @handleFilter="filterOrders" :refreshClicked="refreshClicked"></filter-orders-mobile>
        <div  @click="downloadReportClicked" style="display: flex; align-items: center;gap: 5px; cursor: pointer;">
          <span style="font-size: 12px; color: #e2136e">Download Report</span>
        </div>
      </div>
      <search class="mt-1 mb-2" @handleSearch="searchOrders" :refreshClicked="refreshClicked"></search>
    </section>

    <section class="table">
      <order-list-mobile
          :orders="orders"
          :refreshClicked="refreshClicked"
          :loading="isLoading"
          ref="orderList"
      ></order-list-mobile>
      <pagination
          :isLoading="isLoading"
          :showPrevBtn="showPrevBtn"
          :showNextBtn="showNextBtn"
          :showCount="showCount"
          dataName="orders"
          @getPrevious="getPrevious"
          @getNext="getNext"
          style="margin-top: 24px"
      ></pagination>
    </section>
  </div>
</template>

<script>
import Refresher from "@/components/orderManagement/components/Refresher.vue";
import Search from "@/components/orderManagement/components/Search.vue";
import FilterOrdersMobile from "@/components/orderManagement/components/FilterOrdersMobile.vue";
import OrderListMobile from "@/components/orderManagement/components/OrderListMobile.vue";
import Pagination from "@/components/orderManagement/common/pagination.vue";
import QrScannerDialog from "@/components/orderManagement/qrScanner/QrScannerDialog";
import BackIcon from "@/assets/image/back.svg";
import moment from "moment";
import {generateCsv, initiateFileDownload} from "@/util/Helper";

const CLOCK_UPDATE_INTERVAL = process.env.VUE_APP_CLOCK_UPDATE_INTERVAL;
const AUTO_REFRESH_MIN_THRESHOLD = process.env.VUE_APP_AUTO_REFRESH_MIN_THRESHOLD;
export default {
  name: "OrderManagementMobile",

  components: {
    FilterOrdersMobile, Search, Refresher, QrScannerDialog, Pagination,
    OrderListMobile,
  },

  created() {
    this.getOrderList();
    this.updateClock();
  },

  computed: {
    refreshTimeMessage() {
      if (this.isLoading) {
        return "Updating...";
      }

      if (this.lastRefreshed === null) {
        return "Refresh failed";
      }

      let diff = Math.ceil((this.clockTime - this.lastRefreshed) / 1000 / 60);

      if (this.wasLastRefreshSuccessful) {
        if (diff < 2) return "Updated just now.";
        return `Last updated ${diff} minutes ago.`;
      }

      return `Refresh failed. Last updated ${diff} minutes ago.`;
    },

    showPrevBtn() {
      let leng = this.tokens.length;
      if (leng === 2) {
        return false;
      }
      return true;
    },

    showNextBtn() {
      let leng = this.tokens.length;
      if (this.tokens[leng - 1] === null) {
        return false;
      }
      return true;
    },

    showCount() {
      if (this.orders.length === 0) return [0, 0];

      let leng = this.tokens.length;
      return [1 + this.itemsPerPage * (leng - 2), this.orders.length + this.itemsPerPage * (leng - 2)]
    }
  },

  data() {
    return {
      backIcon: BackIcon,
      itemsPerPage: 25,
      isLoading: false,

      clockTime: null,
      lastRefreshed: null,
      refreshClicked: false,

      orders: [],
      tokens: [" "],

      payload: {
        fromDate: moment(new Date(new Date().setDate((new Date()).getDate() - 90))).format('YYYY-MM-DD 00:00:00'),
        toDate: null,
        orderStatus: null,
        searchString: null,
        nextToken: " "
      },
    }
  },

  methods: {
    async scanQrCode() {
      console.log("Scanner Button Tapped");
      let scanResult = await this.$refs.qrScannerDialog1.scan();
      console.log("scanResult: ", scanResult);
    },

    updateClock() {
      this.clockTime = Date.now();

      if (this.lastRefreshed) {
        let diff = this.clockTime - this.lastRefreshed;
        if (diff > 120 * AUTO_REFRESH_MIN_THRESHOLD) { // auto refresh after 1 hour
          this.refreshRequested();
        }
      }
      if (this.$store.getters.isAuthenticated) {
        setTimeout(() => {
          this.updateClock();
        }, 30 * CLOCK_UPDATE_INTERVAL);
      }
    },

    clearTokens() {
      this.tokens = [" "];
      this.payload.nextToken = this.tokens[0];
    },

    async getOrderList() {
      this.isLoading = true;
      this.$feedback.showLoading();
      try {
        let data = await this.$cppClient.post('/order/list', this.payload);
        this.orders = data.data.orders;
        this.tokens.push(data.data.nextToken)

        this.lastRefreshed = Date.now();
        this.wasLastRefreshSuccessful = true;
      } catch (err) {
        // this.orders = [];
        this.wasLastRefreshSuccessful = false;
        await this.$feedback.showFailed(err.response);
      } finally {
        this.refreshClicked = false;
        this.isLoading = false;
        this.$feedback.hideLoading();
      }
    },

    async getNext() {
      this.payload.nextToken = this.tokens[this.tokens.length - 1];
      await this.getOrderList();
    },

    async getPrevious() {
      this.tokens.pop(); // pop next page token
      this.tokens.pop(); // pop current page token
      this.payload.nextToken = this.tokens[this.tokens.length - 1];
      await this.getOrderList();
    },

    async refreshRequested() {
      this.clearTokens();// clear everything

      this.payload.fromDate = null;
      this.payload.toDate = null;
      this.payload.orderStatus = null;
      this.payload.searchString = null;

      this.refreshClicked = true;

      await this.getOrderList();
    },

    async filterOrders(filters) {
      this.clearTokens();
      this.payload.fromDate = filters.fromDate;
      this.payload.toDate = filters.toDate;
      this.payload.orderStatus = filters.status;

      await this.getOrderList();
    },

    async searchOrders(searchStr) {
      this.clearTokens();
      this.payload.searchString = searchStr ? searchStr.trim() : null;

      await this.getOrderList();
    },

    goBack() {
      this.$router.go(-1);
    },

    async downloadReportClicked() {
      const sourceHeaders = [
        {text: "Invoice ID", value: "invoiceNumber"},
        {text: "Date", value: "createdDate"},
        {text: "Product Name", value: "productName"},
        {text: "Link ID", value: "paymentLinkId"},
        {text: "Cust Wallet", value: "customerPhoneNumber"},
        {text: "Amount", value: "amount"},
        {text: "Txn ID", value: "trxId"},
        {text: "Status", value: "orderStatus"},
        {text: "", value: "details"}
      ];

      let headers = sourceHeaders.filter(header => header.text && String(header.text).length > 0)
      console.debug({headers});

      let csvHeaders = headers.map(header => header.text);
      let csvRows = this.orders.map(order => {
        return headers.map(header => order[header.value]);
      });

      let csvContent = generateCsv(csvHeaders, csvRows);
      console.debug(csvContent)
      let csvFileName = `Order Report Page - ${(new Date().toJSON().slice(0, 10))}.csv`;

      initiateFileDownload(csvContent, csvFileName);
    }
  }
}
</script>

<style scoped>
.orders-container {
  margin: 72px 16px 0;
  background: #FFF;
  padding: 16px;
}

.orders-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.mdi-qrcode-scan {
  font-size: 16px;
}
</style>