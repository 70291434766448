<template>
  <div>
    <v-text-field v-if="isMobile"
        v-model="searchText"
        :placeholder="currentText"
        clearable
        @keydown.enter="handleInput"
        @click:clear="clearSearch"
        single-line
        hide-details
        class="search"
    >
      <template v-slot:append>
        <div @click="handleInput" class="append-icon-container">
          <v-icon class="custom-icon">mdi-magnify</v-icon>
        </div>
      </template>
    </v-text-field>
    <v-text-field v-else
        v-model="searchText"
        label="Search"
        :placeholder="currentText"
        prepend-inner-icon="mdi-magnify"
        @keydown.enter="handleInput"
        clearable
        @click:clear="clearSearch"
        single-line
        hide-details
        class="search"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: ['refreshClicked'],

  mounted() {
    this.getPlaceHolder();
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    },
  },

  data() {
    return {
      currentText:"",
      currentIndex:0,
      placeholders: ["Search by Invoice ID", "Search by Transaction ID", "Search by Wallet", "Search by Link ID"],
      searchText: null,
      searchRules: [
        (v) => !!v || 'Search field is required', // Required validation
        (v) => (v && v.length >= 3) || 'Search text must be at least 3 characters', // Custom validation rule
      ],
    };
  },
  methods: {
    handleInput() {
      this.$emit('handleSearch', this.searchText)
    },
    clearSearch() {
      this.searchText = null;
      this.handleInput();
    },
    getPlaceHolder(){
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.placeholders.length;
        this.currentText = this.placeholders[this.currentIndex];
      }, 2000); // Switch every 1 second
    }
  },
  watch: {
    refreshClicked: function () {
      this.searchText = null;
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  width: 100%;
  border: 1px solid #DDD;
  border-radius: 4px;
  @media screen and ( min-width: 1001px) {
    min-width: 243px;
  }
}

.v-text-field {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

::v-deep .v-input {
  margin-top: 0 !important;
}

::v-deep .v-input__append-inner {
  margin: auto;
}
.append-icon-container {
  height: 32px;
  width: 32px;
  background-color: #e2136e;
  padding: 8px;
  border-radius: 4px;
  display: inline-block; /* Add this to make the appended icon always visible */
}
::v-deep .mdi-magnify {
  color: #fff;
  height: 16px;
  width: 16px;
  padding: 8px;
  @media screen and ( min-width: 1001px ) {
    color: #777;
  }
}
::v-deep .v-input__slot {
  display: flex;
  align-items: center;
  padding: 4px;
  @media screen and ( min-width: 1000px) {
    padding: 4px 5px;
  }
}

::v-deep .v-input__slot::before {
  border-style: none !important;
}

::v-deep .v-icon {
  font-size: 20px;
}

</style>