<template>
  <div>
    <div>
      <div v-if="!filtered" @click="mobileDialog=true" style="display: flex; align-items: center;gap: 5px; cursor: pointer;">
        <img :src="filterIcon" height="16px" width="16px" alt="filter-icon">
        <span style="font-size: 12px; color: #e2136e">Filter By</span>
      </div>
      <div v-else style="display: flex;align-items: center;gap: 16px">
        <div class="filtered" @click="mobileDialog=true" style="display: flex; align-items: center;gap: 5px; cursor: pointer;">
          <img :src="filteredIcon" height="16px" width="16px" alt="filtered-icon">
          <span style="font-size: 12px; color: #e2136e">Filtered</span>
        </div>
        <div class="reset" @click="handleResetFilter" style="display: flex; align-items: center;gap: 5px; cursor: pointer;">
          <span style="font-size: 12px; color: #e2136e">Reset</span>
          <img :src="resetIcon" height="10px" width="10px" alt="reset-icon">
        </div>
      </div>
    </div>
    <v-dialog persistent max-width="320" v-model="mobileDialog">
      <v-card max-width="320" style="padding: 16px">
        <div style="margin-bottom: 16px;display: flex;align-items: center;justify-content: space-between">
          <p style="margin-bottom: 0">Filter By</p>
          <v-icon color="primary" size="18" @click="mobileDialog=false">mdi-close</v-icon>
        </div>
        <div class="filter-by-date">
          <p>Date</p>
          <v-radio-group v-model="selectedDateToFilter" @change="setDateRange" column>
            <v-radio label="Last 7 days" value="7 Days"></v-radio>
            <v-radio label="Last 30 days" value="30 Days"></v-radio>
            <v-row>
              <v-radio label="Custom Date Range" value="Custom"></v-radio>
              <date-picker
                  v-if="selectedDateToFilter==='Custom'"
                  v-model="dateRange"
                  range
                  width="100%"
                  class="date-picker simple-stretch-on-mobile"
                  :disabled-date="filterDisableDates"
              ></date-picker>
            </v-row>
          </v-radio-group>
        </div>
        <div class="filter-by-status">
          <p>Status</p>
          <v-radio-group v-model="selectedStatus" row>
            <v-radio label="Completed" value="Completed"></v-radio>
            <v-radio label="Incomplete" value="Incomplete"></v-radio>
          </v-radio-group>
        </div>
        <v-btn :disabled="disableSubmit" @click="handleFilter" color="primary" width="100%">Submit</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import filterIcon from "@/assets/image/filter.svg";
import filteredIcon from "@/assets/image/filtered-icon.svg";
import resetIcon from "@/assets/image/reset-icon.svg";
export default {
  name: "FilterOrders",
  props: ["refreshClicked"],

  data(){
    return {
      selectedDateToFilter : null,
      dateRange : [],
      selectedStatus : null,

      filters: {
        fromDate: null,
        toDate: null,
        status: null
      },

      filterIcon: filterIcon,
      filteredIcon: filteredIcon,
      resetIcon: resetIcon,

      mobileDialog: false,
      filtered: false,
    }
  },

  computed: {
    disableSubmit(){
      if(this.selectedStatus===null && this.selectedDateToFilter===null){
        return true;
      }else if(this.selectedDateToFilter==="Custom" && (!this.dateRange[0] || !this.dateRange[1])){
        return true;
      }
      return false;
    },
  },
  methods: {
    filterDisableDates(date) {
      const today = new Date();
      const priorDate = new Date(new Date().setDate((new Date()).getDate() - 90));
      return date.getTime() < priorDate.getTime() || date.getTime() > today.getTime();
    },

    setDateRange(){
      switch (this.selectedDateToFilter){
        case "7 Days":
          this.filters.fromDate = moment(new Date(new Date().setDate((new Date()).getDate() - 6))).format('YYYY-MM-DD 00:00:00');
          this.filters.toDate = moment(new Date()).format('YYYY-MM-DD 00:00:00');
          break;
        case "30 Days":
          this.filters.fromDate = moment(new Date(new Date().setDate((new Date()).getDate() - 29))).format('YYYY-MM-DD 00:00:00');
          this.filters.toDate = moment(new Date()).format('YYYY-MM-DD 00:00:00');
          break;
        case "Custom":
          this.filters.fromDate = moment(this.dateRange[0]).format('YYYY-MM-DD 00:00:00');
          this.filters.toDate = moment(this.dateRange[1]).format('YYYY-MM-DD 00:00:00');
          break;
        default:
          this.filters.fromDate = null;
          this.filters.toDate = null;
      }
    },

    handleFilter(){
      this.setDateRange();
      this.filters.status = this.selectedStatus;
      this.filtered = true;
      this.mobileDialog = false;
      this.$emit('handleFilter', this.filters);
    },

    handleResetFilter(){
      this.selectedDateToFilter = null;
      this.dateRange = [];
      this.selectedStatus = null;

      this.filters.fromDate = null;
      this.filters.toDate = null;
      this.filters.status = null;

      this.filtered = false;

      this.$emit('handleFilter', this.filters);
    },
  },

  watch: {
    refreshClicked: function (){
      this.selectedDateToFilter = null;
      this.dateRange = [];
      this.selectedStatus = null;

      this.filters.fromDate = null;
      this.filters.toDate = null;
      this.filters.status = null;

      this.filtered = false;
    }
  }
}
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background-color: #9A9A9A !important;
  color: #FFF !important;
}
.date-picker {
  margin: 0;
}
.mx-datepicker-range {
  width: 232px;
}
>>> .mx-input-wrapper > input {
  border: 1px solid #DDD;
  border-radius: 4px;
  height: 40px;
}
.row {
  margin: 0;
}
.v-dialog {
  width: unset;
  margin: unset;
}
>>> .mdi-radiobox-blank {
  color: #777 !important;
}
>>> .mx-icon-calendar {
  color: #e2136e;
}

>>> .mx-icon-clear {
  color: #e2136e;
}
</style>