<template>
  <div style="margin-bottom: 50px;height: 65vh;">
    <v-data-table
        v-model="selectedOrders"
        :loading="isLoading"
        :headers="headers"
        :items="orders"
        item-key=invoiceNumber
        loading-text="Loading orders..."
        no-data-text="No orders available"
        :hide-default-footer="true"
        :hide-default-header="true"
        :disable-select="true"
        show-select
        fixed-header
        height="100%"
        disable-pagination
        class="table"
        ref="dataTable"
    >
      <template v-slot:header="{ props }">
        <tr style="
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: #F9F9F9;
            color: #777;
            font-size: 14px;
            font-weight: 400;
        ">
          <th
              v-for="header in props.headers"
              :key="header.text+header.value"
              style="padding: 5px 0;"
              class="thead"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>

      <template v-slot:item.productName="{item}">
        {{ item.productName ? item.productName : defaultPname }}
      </template>

      <template v-slot:item.paymentLinkId="{item}">
        <div @click="copyPLid(item.paymentLinkId)" style="display: flex; align-items: center;gap: 2px;cursor: pointer">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{
                item.paymentLinkId ? (item.paymentLinkId.slice(0, 2) + "..." + item.paymentLinkId.slice(-4, -1)) : "..."
              }}
            </span>
            </template>
            <span>
            {{ item.paymentLinkId }}
          </span>
          </v-tooltip>
          <img :src="copyIcon" style="color: #e2136e;" alt="copy-icon">
        </div>
      </template>

      <template v-slot:item.amount="{item}">
        <span> ৳{{ item.amount }} </span>
      </template>

      <template v-slot:item.orderStatus="{item}">
      <span
          :style="{color: item.orderStatus==='Incomplete'? '#FFB829' : '#01846C'}"
          class="mdi mdi-circle"
      >
      </span>
        {{ item.orderStatus }}
      </template>

      <template v-slot:item.details="{item}">
        <v-btn color="primary" outlined @click="viewOrderDetails(item.orderId)">View Details</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import copyIcon from "@/assets/image/copy-icon-pink.svg";

export default {
  name: "OrderList",
  props: {
    orders: Array,
    isLoading: Boolean,
    payloadChanged: Boolean,
  },

  data() {
    return {
      selectedOrders: [],
      headers: [
        {text: "Invoice ID", value: "invoiceNumber"},
        {text: "Date", value: "createdDate"},
        {text: "Product Name", value: "productName"},
        {text: "Link ID", value: "paymentLinkId"},
        {text: "Cust Wallet", value: "customerPhoneNumber"},
        {text: "Amount", value: "amount"},
        {text: "Txn ID", value: "trxId"},
        {text: "Status", value: "orderStatus"},
        {text: "", value: "details"}
      ],
      defaultPname: "N/A",
      shouldShowMarkAsComplete: false,
      copyIcon: copyIcon
    }
  },

  watch: {
    selectedOrders: function () {
      if (this.selectedOrders.length === 0) {
        this.shouldShowMarkAsComplete = false;
      } else {
        this.shouldShowMarkAsComplete = !!this.selectedOrders.every(order => order.orderStatus === "Incomplete");
      }

      const selectedOrders = this.selectedOrders.map((order) => {
        return {
          orderId: order.orderId,
          orderStatus: "Completed"
        };
      })
      this.$emit("selectionChanged", selectedOrders, this.shouldShowMarkAsComplete);
    },

    payloadChanged: function (val) {
      if (val) {
        this.selectedOrders = [];
      }
    },
  },

  methods: {
    viewOrderDetails(orderId) {
      this.$router.push({
        name: "order-management-details",
        params: {orderId}
      });
    },

    async copyPLid(text2copy) {
      try {
        await navigator.clipboard.writeText(text2copy);
        await this.$feedback.showShortToast("Link ID Copied!");
      } catch (err) {
        await this.$feedback.showSubtleErrorToast("Failed to copy");
      }
    }
  }
}
</script>

<style scoped>
.v-data-table {
  color: #464646;
  margin-top: 24px;
}

.v-data-table-progress {
  z-index: 3;
}

.table {
  margin-bottom: 50px;
  height: 100%;
}

.table, .thead {
  text-align: start;
  vertical-align: center;
}

>>> td {
  padding: 0 !important;
}

>>> tr:hover {
  background-color: unset !important;
}

>>> tr:active {
  background-color: unset !important;
}

>>> .mdi-checkbox-blank-outline::before {
  color: #777777 !important;
}

>>> .mdi-checkbox-marked::before {
  color: #e2136e !important;
}

>>> .mdi-minus-box::before {
  color: #e2136e !important;
}
</style>